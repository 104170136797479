/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { MDBCloseIcon } from "mdbreact"
import { baseURL, CONFIG, errorToast, successToast, ToastTable } from '../../../configs/exports';
import axios from 'axios';
// import img from '../../../_assets/img/logo.png'
// import Select from 'react-select';
import Alert from '../../../shared/components/Alert';

import './style2.css';

const LogIn = () => {

  // initialing hooks
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = React.useState('');


  const [full_name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password_again, setPasswordAgain] = React.useState('');


  const [password, setPassword] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [openPassword, isOpenPassword] = React.useState(false);
  const [loading, isLoading] = React.useState('');
  const [loggedin, isLoggedIn] = React.useState(localStorage.getItem("isLoggedIn") ? true : false);
  // toggle password visibility



  useEffect(() => {

    // check if user was logged in
    if (loggedin) {
      // window.location.href = "dashboard";
    }
  }, [loggedin]);


  const handleChangeUsername = event => {
    setUsername(event.target.value);
  };

  const handleChangePassword = event => {
    setPassword(event.target.value);
  };


  const showPasswordToggle = () => {
    // eslint-disable-next-line
    if (showPassword == true) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };


  //submit function
  const onSubmit = e => {
    e.preventDefault();
    isLoading(true);
    if (username === "" || password === "") {
      setStatusMessage("")
      setError(true)
      setErrorMessage("Username and password is required")
      setSuccess(false)
      isLoading(false)
    } else {
      let formData = {
        "name": username,
        "password": password
      }
      axios.post(baseURL + 'login', formData, CONFIG).then((response) => {
        if (response.data.success) {
          localStorage.setItem("USER", response.data.user)
        
          localStorage.setItem("USERID", response.data.user.name)
          localStorage.setItem("token", response.data.access_token)
          localStorage.setItem("mobipesa_name", response.data.access_token);
          isLoggedIn(true)

          successToast("Login Success! Redirecting....")

          localStorage.setItem("isLoggedIn", true);
          // // eslint-disable-next-line
          window.setTimeout(() => {
            window.location.href = "subscribers"
            isLoading(false)
            // }
          }, 10);
        }
        else {

          isLoading(false)
          errorToast(response.data.message)
          window.setTimeout(() => {
            isLoading(false)
            setError(false)
          }, 5000);
        }
      }).catch(error => {

        if (error.response.data.error) {
          errorToast(error.response.data.error)
        } else {

          errorToast(error.response.data)
        }
        isLoading(false)

      });
    }
  };

  // return the UI
  return (
    <div className="elite-login">

      <ToastTable />




      <div className="elite-login-sec">
        <div className="row">
          <div className="col-md-6 elite-login-left">
            <div className="carousel-wrap center">
              <div className="item">
                <div className="item-sec center">
                  <div className="login_slider_image center">
                    {/* <img className='center' src={img} alt="logo" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 elite-login-right">
            <h1 className="elite-login-head">REMCO</h1>
            <form className="elite-login-form" onSubmit={onSubmit} >

              <div className="elite-form-field">
                <label htmlFor="user_email">Your username</label>
                <input className="form-control"
                  placeholder="Enter Username (2547)"
                  id="elite-username"
                  required="required"
                  type="text"
                  onChange={handleChangeUsername}
                  style={{ borderColor: "grey" }}
                  value={username}
                  name="username" />
              </div>
              <div className="elite-form-field">
                <label htmlFor="user_email">Password</label>
                <input
                  placeholder="Enter your password"
                  id="elite-email"
                  className="form-control"
                  required="required"
                  style={{ borderColor: "grey" }}
                  value={password}
                  onChange={handleChangePassword}
                  type={showPassword === true ? "text" : "password"}
                />

                <input type="hidden" name="user_timezone" id="user_timezone" />

                <span toggle="#password-field" onClick={showPasswordToggle} className="fa fa-fw fa-eye field-icon toggle-password" />

                <br /><br />
                <br /><br />
                <br /><br />

              </div>


              <button type="submit" className="elite-form-btn"> {
                loading ? "Please wait..." : "Sign In"}
              </button>
            </form>
          </div>
          {/* <p onClick={this.resetpasswordchange}>Forgot Password?</p><br></br> */}
        </div>
      </div>
    </div>
  );
}

export default connect(state => ({ theme: state.theme }))(LogIn);
